<template>
  <div>
    <!-- {{ train.source }} -->
    <v-card :style="`border: 3px solid ${borderColor}`" :elevation="trainCardElevation" :class="trainCardRoundedClass" height="120" width="250">
      <v-card-text class="pa-0 ma-0 fill-height">
        <v-container fill-height class="pa-0 ma-0">
          <v-row class="pa-0 ma-0 fill-height">
            <v-col cols="2" :class="`${iconBarColor} px-0 mx-0 d-flex text-center`" style="justify-content: center; align-items: start;">
              <v-icon color="error" v-if="train.discrepancy">mdi-alert-outline</v-icon>
            </v-col>
            <v-col cols="10">
              <v-container fill-height class="pa-0 ma-0">
                <v-row>
                  <v-col cols="12" :class="`${hasPreadvice && hasPassage ? 'pb-0':''} d-flex justify-end`" v-if="hasPreadvice">                    
                    <v-icon color="grey" v-if="train.source === 'COP'" title="Preavviso da COPINO">mdi-file-clock-outline</v-icon>
                    <v-icon color="grey" v-if="train.source === 'API'" title="Preavviso da PORTALE">mdi-web-clock</v-icon>
                    <span class="text-body-1 text-right font-weight-light pl-1">{{ train.preadvice.trainDate | formatTimeFromDate }}</span>
                  </v-col>
                  <v-col cols="12" :class="`${hasPassage && hasPreadvice ? 'py-0':''} d-flex justify-end`" v-if="hasPassage">
                    <v-icon color="grey">mdi-cctv</v-icon>
                    <span class="text-body-1 text-right font-weight-light pl-1">{{ train.passage.trainDate | formatTimeFromDate }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-h6 text-right font-weight-bold d-flex justify-end" style="white-space: nowrap;">
                    {{ voyage }}
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card v-if="railRegistrationBarVisible" color="teal lighten-4" elevation="10" class="rounded-t-0 rounded-b-lg" height="40" width="250">
      <v-card-text class="fill-height pa-0 ma-0">
        <v-container fill-height class="pa-0 ma-0">
          <v-row class="pa-0 ma-0 fill-height" align="center">
            <v-col cols="12" class="py-0 my-0 text-right">
              <v-icon v-if="train.rrStatus === 'OK'" color="success">mdi-check-bold</v-icon>
              <v-icon v-else color="warning">mdi-alert</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FormatsMixins from "../../../mixins/FormatsMixins";

export default {
  name: "TrainCard",
  props: {
    train: {
      type: Object,
      required: true,
    },
  },
  mixins: [FormatsMixins],
  computed: {
    borderColor() {
      if (this.train && this.train.tosVoyage && this.train.tosVoyage !== null) {
        return "#1976d2";
      }
      return "#F9A825";
    },
    iconBarColor() {
      if (this.train && this.train.tosVoyage && this.train.tosVoyage !== null) {
        return "primary";
      }
      return "warning";
    },
    gateAutomationIconVisible() {
      if (this.train) {
        if (this.hasPassage) {
          return true;
        }
      }
      return false;
    },
    voyage() {
      if (this.train) {
        if (this.train.tosVoyage) {
          return this.train.tosVoyage;
        } else if (this.hasPassage) {
          return this.train.passage.voyage;
        } else if (this.hasPreadvice) {
          return this.train.preadvice.voyage;
        }
      }
      return null;
    },
    trainCardRoundedClass() {
      if (this.train) {
        if (this.train.rrDate && this.train.rrDate !== null) {
          return "rounded-t-lg rounded-b-0";
        }
        return "rounded-lg";
      }
      return null;
    },
    trainCardElevation() {
      if (this.train) {
        if (this.train.rrDate && this.train.rrDate !== null) {
          return 10;
        }
        return 0;
      }
      return null;
    },
    railRegistrationBarVisible() {
      if (this.train) {
        if (this.train.rrDate && this.train.rrDate !== null) {
          return true;
        }
      }
      return false;
    },
    hasPreadvice() {
      return this.train && this.train.preadvice && this.train.preadvice !== null && this.train.preadvice.voyage && this.train.preadvice.voyage !== null;
    },
    hasPassage() {
      return this.train && this.train.passage && this.train.passage !== null && this.train.passage.voyage && this.train.passage.voyage !== null;
    },
  },
  methods: {

  },
};
</script>

<style></style>

<template>
    <component v-bind:is="homeComponent"></component>
</template>

<script>
import Dashboard from "./Dashboard";
import Lavorazioni from "./lavorazioni/lavorazioni";
import InventoryVisits from "@/views/yard/inventory/InventoryVisits"
import Gate from "@/views/rails/gate/Gate"
export default {
  name: "Home",
  data() {
    return {
      homeComponent: null,
    }
  },
  computed:{
    homeComponentName(){
        const roles=this.$store.getters['authentication/loggedUser'].roles;
        if (roles && roles.join(",") == "GRUISTA" ) return "Lavorazioni";
        if (roles.length==1 && roles[0]== "RAIL_GATE") return "Gate" ;
        if (!roles.includes("USER")) return "InventoryVisits" ;
        return "Dashboard";
    },
  },
  mounted() {
     this.homeComponent= this.homeComponentName;
  },
	components: {
		Dashboard,
    Lavorazioni,
    InventoryVisits,
    Gate
	},
}
</script>
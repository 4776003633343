<template>
  <div>
    <v-container v-if="showFilters">
      <v-row align="center">
        <v-col cols="3">
          <v-container>
            <v-row align="center">
              <v-col cols="2" class="text-right">
                <v-btn small outlined fab left color="primary" @click="incrStartDate(-1)" elevation="3" :disabled="!searchByDateEnabled">
                  <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="8" class="text-center">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="startDateFormat" persistent-hint prepend-icon="event" v-on="on" :disabled="!searchByDateEnabled" />
                  </template>
                  <v-date-picker v-model="startDateIsoFormat" no-title @input="menu1 = false" @change="startDateChanging" :disabled="!searchByDateEnabled" />
                </v-menu>
              </v-col>
              <v-col cols="2" class="text-left">
                <v-btn small outlined fab right color="primary" @click="incrStartDate(1)" elevation="3" :disabled="!searchByDateEnabled">
                  <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col cols="3">
          <v-switch
            v-model="viewAll"
            inset
            :title="tLabel('VISUALIZZA TUTTI')"
            :label="tLabel('VISUALIZZA TUTTI')"
            @change="setDailyTrains"
            :disabled="!searchByDateEnabled"
          ></v-switch>
        </v-col>

        <v-spacer />

        <v-col cols="4">
          <v-container>
            <v-row align="center">
              <v-col cols="9">
                <v-text-field label="Viaggio" v-model="codiceViaggioFilter" />
              </v-col>
              <v-col cols="3" style="display: flex; flex-direction: row; justify-content: center;">
                <v-btn
                  small
                  outlined
                  fab
                  right
                  color="grey"
                  style="margin: 0 5px"
                  @click="
                    searchByDateEnabled = true;
                    codiceViaggioFilter = null;
                    fetchData();
                  "
                  elevation="3"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                  small
                  outlined
                  fab
                  right
                  color="primary"
                  style="margin: 0 5px"
                  elevation="3"
                  @click="
                    searchByDateEnabled = false;
                    fetchData();
                  "
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row align="center">
        <v-col cols="12" v-if="dailyTrains && dailyTrains!==null && dailyTrains.length>0">
          <div v-for="dailyTrain in dailyTrains" :key="dailyTrain.timestamp">
            <v-container>
              <v-row class="text-right">
                <v-col cols="12">
                  <span :class="`text-body-1 ${dailyTrain.isToday ? 'red--text font-weight-bold' : 'font-weight-light'}`">{{ dailyTrain.dateTime | formatShortDateFormat }}</span>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0">
                <v-col cols="12" class="pt-0 mt-0"> <v-divider :class="dailyTrain.isToday ? 'red' : null"></v-divider> </v-col>
              </v-row>
              <v-row v-if="dailyTrain.trains && dailyTrain.trains !== null && dailyTrain.trains.length > 0">
                <v-col cols="2" v-for="train in dailyTrain.trains" :key="train.uid">
                  <TrainCard :train="train" @click.native="goToTrainDetail(train.uid)" class="pointer" />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="text-h5 text-uppercase font-weight-light grey lighten-5">
                  {{ tLabel("Nessun treno") }}
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <v-col cols="12" v-else>
          <div>
            <v-container>
              <v-row class="pt-0 mt-0">
                <v-col cols="12" class="pt-0 mt-0"> <v-divider></v-divider> </v-col>
              </v-row>             
              <v-row>
                <v-col cols="12" class="text-h5 text-uppercase font-weight-light grey lighten-5">
                  {{ tLabel("Nessun treno") }}
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import FormatsMixins from "../../../mixins/FormatsMixins";
import TrainCard from "./TrainCard";

export default {
  name: "GateTrainDailyCalendar",
  data() {
    return {
      startDate: null,
      startDateFormat: "",
      startDateIsoFormat: "",
      dailyTrains: [],
      codiceViaggioFilter: null,
      viewAll: true,
      menu1: null,
      searchByDateEnabled: true,
      interval: null
    };
  },
  props: {
    showFilters: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatsMixins],
  components: { TrainCard },
  async created() {
    this.interval = setInterval(async () => {
      try {
        await this.fetchData();
      } catch (e) {
        console.log(e);
      }
    }, 30000);
  },
  async mounted() {
    this.startDate = moment().startOf("day");
    if (this.filters && this.filters !== null) {
      console.log("filters", this.filters);
      this.startDate = this.filters.startDate;
      this.viewAll = this.filters.viewAll;
      this.codiceViaggioFilter = this.filters.codiceViaggioFilter;
      this.searchByDateEnabled = this.filters.searchByDateEnabled;
    }
    await this.fetchData();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    async fetchData() {
      this.startDateFormat = this.startDate.format("DD/MM/YYYY");
      this.startDateIsoFormat = this.startDate.format("YYYY-MM-DD");
      if (this.searchByDateEnabled || !this.codiceViaggioFilter) {
        await this.setDailyTrains();
      } else {
        await this.searchByVoyage();
      }
    },
    goToTrainDetail(uid) {
      this.$router.push({
        name: "railGateTrainDetail",
        params: {
          uid: uid,
          filters: {
            startDate: this.startDate,
            codiceViaggioFilter: this.codiceViaggioFilter,
            viewAll: this.viewAll,
            searchByDateEnabled: this.searchByDateEnabled,
          },
        },
      });
    },
    isVoyageInDate(voyage, date) {
      if (voyage && voyage.trainDate && voyage.trainDate !== null && moment(voyage.trainDate).format("YYYYMMDD") === date.format("YYYYMMDD")) {
        return true;
      }
      return false;
    },
    async searchByVoyage() {
      console.log("codiceViaggioFilter", this.codiceViaggioFilter);
      var voyages = await this.$api.railGateData.getVoyageByVoyageStartingWith(this.codiceViaggioFilter);
      // console.log("voyages", voyages);

      this.dailyTrains = new Array();
      if (voyages && voyages !== null && voyages.length > 0) {
        var days = new Array();
        voyages.forEach((v) => {
          if (!days.find((d) => moment(v.trainDate).format("YYYYMMDD") === d)) {
            days.push(moment(v.trainDate).format("YYYYMMDD"));
          }
        });
        days = days.sort((a, b) => (moment(a) > moment(b) ? 1 : -1));
        // console.log("days", days);

        days.forEach((d) => {
          this.dailyTrains.push({
            timestamp: d,
            dateTime: moment(d).toDate(),
            isToday: moment().startOf("day").format("YYYYMMDD") === d,
            trains: voyages.filter((v) => this.isVoyageInDate(v, moment(d))).sort((a, b) => (moment(a.trainDate) > moment(b.trainDate) ? 1 : -1)),
          });
        });
        // console.log("------------------- searchByVoyage dailyTrains", this.dailyTrains, JSON.stringify(this.dailyTrains));
      }
    },
    async setDailyTrains() {
      let dateFrom = moment(this.startDate).add(-1, "day").toISOString();
      let dateTo = moment(this.startDate).add(2, "day").subtract(1, "seconds").toISOString();
      // console.log("dateFrom", dateFrom, moment(this.startDate).add(-1, "day").format("DD/MM/YYYY HH:mm:ss"));
      // console.log("dateTo", dateTo, moment(this.startDate).add(2, "day").subtract(1, "seconds").format("DD/MM/YYYY HH:mm:ss"));

      var voyages = await this.$api.railGateData.getVoyagesByDateRange(dateFrom, dateTo);
      if (!this.viewAll) {
        voyages = voyages.filter((v) => !v.tosVoyage || v.tosVoyage === null || (v.rrStatus && v.rrStatus === "KO"));
      }
      // console.log("voyages", voyages ? voyages.length : 0, voyages, JSON.stringify(voyages));

      this.dailyTrains = new Array();

      let startDateBefore1Day = moment(this.startDate).add(-1, "day");
      let startDateAfter1Day = moment(this.startDate).add(1, "day");

      let before1DayTrains = voyages.filter((v) => this.isVoyageInDate(v, startDateBefore1Day)).sort((a, b) => (moment(a.trainDate) > moment(b.trainDate) ? 1 : -1));
      // console.log("before1DayTrains", before1DayTrains);
      this.dailyTrains.push({
        timestamp: startDateBefore1Day.format("YYYYMMDD"),
        dateTime: startDateBefore1Day.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === startDateBefore1Day.format("YYYYMMDD"),
        trains: before1DayTrains,
      });

      let startDateTrains = voyages.filter((v) => this.isVoyageInDate(v, this.startDate)).sort((a, b) => (moment(a.trainDate) > moment(b.trainDate) ? 1 : -1));
      // console.log("startDateTrains", startDateTrains);
      this.dailyTrains.push({
        timestamp: this.startDate.format("YYYYMMDD"),
        dateTime: this.startDate.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === this.startDate.format("YYYYMMDD"),
        trains: startDateTrains,
      });

      let after1DayTrains = voyages.filter((v) => this.isVoyageInDate(v, startDateAfter1Day)).sort((a, b) => (moment(a.trainDate) > moment(b.trainDate) ? 1 : -1));
      // console.log("after1DayTrains", after1DayTrains);
      this.dailyTrains.push({
        timestamp: startDateAfter1Day.format("YYYYMMDD"),
        dateTime: startDateAfter1Day.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === startDateAfter1Day.format("YYYYMMDD"),
        trains: after1DayTrains,
      });

      // console.log("------------------- setDailyTrains dailyTrains", this.dailyTrains, JSON.stringify(this.dailyTrains));
    },
    startDateChanging() {
      this.startDate = moment(this.startDateIsoFormat);
      this.$refs.menu1 = false;
      this.fetchData();
    },
    incrStartDate(days) {
      this.startDate = moment(this.startDateIsoFormat).add(days, "days");
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>

<template>
  <div>
    <GateTrainDailyCalendar showFilters :filters="filters"/>
  </div>
</template>

<script>
import GateTrainDailyCalendar from "../../../components/rails/gate/GateTrainDailyCalendar";

export default {
  name: "railGate",
  components: { GateTrainDailyCalendar },
  props: {
    filters: {
      type: Object,
      default: null,
    },
  }
};
</script>

<style></style>
